

export const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    // const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
  
    const bytes = atob(data);
    let length = bytes.length;
    let out = new Uint8Array(length);
  
    /* while (length--) {
        out[length] = bytes.charCodeAt(length);
    } */
  
    for (let i = 0; i < length; ++i) {
      out[i] = bytes.charCodeAt(i);
    }
  
    return new Blob([out], { type: 'application/pdf' });
  };