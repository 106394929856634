import { InMemoryCache } from '@apollo/client';

const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          
        }
      }
    }
  });

export default cache;