import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Divider from '@mui/material/Divider';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLazyQuery, useApolloClient } from '@apollo/client';
import { GET_RAD_RESULTS, GET_RAD_RESULTS_STUDY, GET_RAD_RESULTS_REPORT } from '../GraphQL/queries'
import { base64toBlob } from '../utils/helpers'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.retinarx.cl/">
        RetinaRX Servicios Usuario
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn({ token, setToken, setMessageError }) {


  const [getRadResultsQuery, {
    loading: loadingRadResults,
    data: dataRadResults,
    error: errorRadResults,
    called: calledRadResults
  }] = useLazyQuery(GET_RAD_RESULTS, { fetchPolicy: 'no-cache' });


  const [contextState, setContextState] = React.useState({})
  const [loadingGetRadStudy, setLoadingGetRadStudy] = React.useState(false)
  const [loadingGetRadReport, setLoadingGetRadReport] = React.useState(false)

  const client = useApolloClient()
  const linkRef = React.useRef()


  React.useEffect(() => {

    if (dataRadResults?.getRadResults?.secret) {

      setMessageError(null)

      setToken(dataRadResults?.getRadResults?.secret)

    } else {

      setToken(null)
      if (calledRadResults && !loadingRadResults) setMessageError('Evento no informado o datos erroneos')


    }

  }, [dataRadResults])



  React.useEffect(() => {

    if (errorRadResults) {

      setMessageError('Evento no informado o datos erroneos')

    }

  }, [errorRadResults])


  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessageError(null)
    const data = new FormData(event.currentTarget);

    setContextState({
      patient: data.get('patientId'),
      event: data.get('eventFUUID')
    })

    getRadResultsQuery({
      variables: {
        patient: data.get('patientId'),
        event: data.get('eventFUUID')
      }
    })

  };

  const handleGetReport = async () => {

    setLoadingGetRadReport(true)

    const getRadResultsReportQue = await client.query({
      query: GET_RAD_RESULTS_REPORT,
      fetchPolicy: 'no-cache',
      variables: {
        patient: contextState.patient,
        event: contextState.event
      }
    })

    if (getRadResultsReportQue?.data?.getRadResultsReport?.report) {

      const blobReport = base64toBlob(getRadResultsReportQue?.data?.getRadResultsReport?.report)

      const viewerUrl = URL.createObjectURL(blobReport);

      linkRef.current.href = viewerUrl;

      linkRef.current.click();

      setLoadingGetRadReport(false)

    } else {

      setLoadingGetRadReport(false)
      setMessageError('Error desplegando informe')

    }

  };

  const handleGetStudy = async () => {
    setLoadingGetRadStudy(true)

    const getRadResultsStudyQue = await client.query({
      query: GET_RAD_RESULTS_STUDY,
      fetchPolicy: 'no-cache',
      variables: {
        patient: contextState.patient,
        event: contextState.event
      }
    })

    if (getRadResultsStudyQue?.data?.getRadResultsStudy?.study) {

      const viewerUrl = getRadResultsStudyQue?.data?.getRadResultsStudy?.study
      linkRef.current.href = viewerUrl;
      linkRef.current.click();
      setLoadingGetRadStudy(false)

    } else {

      setLoadingGetRadStudy(false)
      setMessageError('Error desplegando estudio')

    }

  };

  return (
    <ThemeProvider theme={theme}  >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundImage: 'linear-gradient( 358.4deg,  rgba(249,151,119,1) -2.1%, rgba(98,58,162,1) 90% )',
        height: '100vh',
        maxHeight: '100vh',
        // minHeight: '100%',
        //boxSizing: 'border-box',
        //overflow: 'auto'
      }}
      >
        <Box
          sx={{
            alignItems: 'center',
            marginTop: 8,
            marginBottom: 17,
            height: '90vh',
            maxHeight: '90vh'
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Entrega de exámenes
          </Typography>
          <Typography component="h1" variant="h6">
            Servicios Usuario
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="patientId"
              label="RUT o Pasaporte"
              InputProps={{ sx: { backgroundColor: 'transparent' } }}
              name="patientId"
              autoComplete="patientId"
              autoFocus
              variant='filled'
              sx={{
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#000000'
                }
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="eventFUUID"
              label="ID de Evento"
              //InputLabelProps={{sx: {backgroundColor: 'white'}}}
              InputProps={{ sx: { backgroundColor: 'transparent' } }}
              //type="password"
              id="eventFUUID"
              //autoComplete="current-password"
              variant='filled'
              sx={{
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#000000'
                }
              }}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
              sx={{alignItems: 'center'}}
            /> */}
            <LoadingButton
              loading={loadingRadResults}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Enviar
            </LoadingButton>
            {/* <Grid Box>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}

            {token && <Grow
              in={!!token}
              {...(!!token ? { timeout: 10000 } : {})}

            >
              <div>
                <div>
                  <Divider style={{ paddingTop: '30px', paddingBottom: '20px' }}>Resultados</Divider>
                </div>
                <Grid container justifyContent="center">
                  <Grid item textAlign="center" xs={6} md={6} lg={6}>
                    <LoadingButton variant='contained' loading={loadingGetRadStudy} onClick={handleGetStudy}> {'Ver Imagenes'} </LoadingButton>
                  </Grid>
                  <Grid item textAlign='center' xs={6} md={6} lg={6}>
                    <LoadingButton variant='contained' loading={loadingGetRadReport} onClick={handleGetReport} > {'Ver Informe'} </LoadingButton>
                  </Grid>
                </Grid>
                <a
                  href='http://'
                  ref={linkRef}
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ visibility: 'hidden', height: 0, width: 0 }}
                >
                </a>
              </div>
            </Grow>
            }
          </Box>
        </Box>
        <Copyright sx={{ height: '10vh', maxHeight: '10vh' }} />
      </Box>
    </ThemeProvider>
  );
}