import * as React from 'react';
import './App.css';
// import Layout from './views/Layout';
import Login from './views/Login';
// import RoutesControl from './components/routesControl';
// import { GlobalUserContext } from './states/states'
// import { BrowserRouter as Router } from 'react-router-dom'
import Alert from '@mui/material/Alert';
// import { getMessageError } from './setMessagesError'
import { setAccessKey } from './setKey'
 

function App() {

  const [token, setToken] = React.useState('')
  const [messageError, setMessageError] = React.useState(null)

  React.useEffect(() => {
    if (token) {
      setAccessKey(token)
    } else {
      setAccessKey(null)
    }
  }, [token])

  // React.useEffect(() => {
  //   if(getMessageError()) {
  //     setMessageError(getMessageError())
  //   } else {
  //     setMessageError(null)
  //   }
  // }, [getMessageError()])
  
 // console.log("messageError: ", messageError )

  return (
    <>
        {/* {token ? 
        <GlobalUserContext.Provider value={userProperties} >
          <Router>
          <Layout token={token} setToken={setToken}>
            <RoutesControl />
          </Layout> 
          </Router>
        </GlobalUserContext.Provider>
          : 
          <Login setToken={setToken}/> }   */}
          {messageError ? <Alert severity='error' onClose={() => {setMessageError(null)}}>{messageError}</Alert> : <></> }
          <Login token={token} setToken={setToken} setMessageError={setMessageError}/>   
    </>

  );
}

export default App;
