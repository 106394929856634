import { gql } from '@apollo/client'

export const GET_RAD_RESULTS = gql`
  query GetRadResults(
    $event: String!
    $patient: String! 
  ) {
    getRadResults(
      event: $event
      patient: $patient
    ) {    
      result  
      detail
      secret      
    }
  }
`;

export const GET_RAD_RESULTS_STUDY = gql`
  query GetRadResultsStudy(
    $event: String!
    $patient: String! 
  ) {
    getRadResultsStudy(
      event: $event
      patient: $patient
    ) {    
      result  
      study      
    }
  }
`;

export const GET_RAD_RESULTS_REPORT = gql`
  query GetRadResultsReport(
    $event: String!
    $patient: String! 
  ) {
    getRadResultsReport(
      event: $event
      patient: $patient
    ) {    
      result  
      report      
    }
  }
`;