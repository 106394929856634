let messageError = null;

export function setMessageError(message) {
    messageError = message
    console.log("messageError: ", messageError);
} 

export function getMessageError() {
    if( messageError ) {
        return messageError
    }
}