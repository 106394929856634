import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ApolloClient, ApolloProvider, from, createHttpLink } from '@apollo/client';
//import { createUploadLink } from 'apollo-upload-client';
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import {getAccessKey } from './setKey'
import { setMessageError } from './setMessagesError'; 
//import { TokenRefreshLink,  } from 'apollo-link-token-refresh'
//import jwtDecode from 'jwt-decode';
import cache  from './GraphQL/cache';



import reportWebVitals from './reportWebVitals';


const httpLink = createHttpLink({
  uri: process.env.REACT_APP_LOCAL_BACKEND_URL || 'https://pop.medphs.com/connector',  
  credentials: 'include'
});

// const refreshLink = new TokenRefreshLink({
//   accessTokenField: "token",
//   isTokenValidOrUndefined: () => {
//     const token = getAccessKey();
//     if(!token ) {
//       return true
//     }
//     try {
//       const {exp} = jwtDecode(token);
//       if (Date.now() >= exp * 1000) {
//         return false;
//       } else {
//         return true
//       }
//     } catch {
//       return false
      
//     }
//   },
//   fetchAccessToken: () => {
//     return fetch(process.env.REACT_APP_LOCAL_GMARS_URL || "https://blues.medphs.com/gmars", {
//       method: "POST",
//       credentials: "include"
//     });
//   },
//   handleFetch: token => {
//     setAccessKey(token)
//   },
//   handleError: err => {
//     setMessageError();
//     setAccessKey();
//     console.log(err)
//   }
// })

//Check if there is any network error and set a message in setMessageErrorFunction
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors ){

    graphQLErrors.forEach(({ message, locations, path }) => {

      console.log('messageError', message)
    
        setMessageError(
          `BE: ${message}`
        )

    }
    )};
  if (networkError) {

    //setMessageError(`[Network error]: Please check your connection and try again`)
    setMessageError(`${networkError}: Please check your connection and try again`)
  };
});

const authLink = setContext((_, { headers }) => {
  const accessKey = getAccessKey();
  
  if(accessKey) {
    return {
      headers: {
        ...headers,
        Authorization: accessKey ? `Bearer ${accessKey}` : "",   
      }
    }
  }
});

export const client = new ApolloClient({
   link: from([
     //refreshLink, 
     errorLink,
     authLink,
     httpLink,
   ]),
   cache
})

ReactDOM.render(
  <React.StrictMode>
     <ApolloProvider client={client}>
        <App />   
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
